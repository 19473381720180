import Vue from 'vue';
import App from './App.vue';
import MainPage from "@/pages/MainPage";
import VueRouter from "vue-router";
import VueMobileDetection from 'vue-mobile-detection';

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueMobileDetection);

const routes = [
    {
        path: '/', component: MainPage,
    },
];

const router = new VueRouter({
    routes // short for `routes: routes`
})

new Vue({
    render: h => h(App),
    router
}).$mount('#app');
